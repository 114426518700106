import { render, staticRenderFns } from "./VideoAdd.vue?vue&type=template&id=180c0425&scoped=true"
import script from "./VideoAdd.vue?vue&type=script&lang=js"
export * from "./VideoAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "180c0425",
  null
  
)

export default component.exports