<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon small class="mr-2" v-bind="attrs" v-on="on" @click="openDialog">
        mdi-history
      </v-icon>
    </template>

    <span>Historial de estados</span>

    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialog" max-width="600px" persistent>
      <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="text-subtitle-1 text-md-h5">Historial de estados
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn class="hidden-sm-and-down" icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        <v-tabs v-model="tab">
           <v-tab v-for="role in roles" :key="role">
            {{ role }}
           </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="role in roles" :key="role">
            <ItemHistorialChange :historial="historialItems(role)"/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </v-tooltip>
</template>
<script>
import ItemHistorialChange from "@/components/historial-changes/ItemHistorialChange.vue";

export default {
    name: "HistorialChanges",
    components: {
      ItemHistorialChange
    },
    props: ["item", "roles"],
    data(){
      return {
        dialog: false,
        tab: null,
      }
    },
    methods: {
      openDialog() {
        this.dialog = true;
      },
      historialItems(tab){
        const f = this.item.changes_states.filter(e=>e.type_role == tab);
        return f ? f.reverse() : [];
      }
    }
}
</script>
<style>
</style>