import { render, staticRenderFns } from "./ItemHistorialChange.vue?vue&type=template&id=49c16186&scoped=true"
import script from "./ItemHistorialChange.vue?vue&type=script&lang=js"
export * from "./ItemHistorialChange.vue?vue&type=script&lang=js"
import style0 from "./ItemHistorialChange.vue?vue&type=style&index=0&id=49c16186&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c16186",
  null
  
)

export default component.exports