<template>
  <v-card
      class="mx-auto"
      min-width="600"
      min-height="400"
  >
    <v-card-text class="py-0">
      <v-timeline v-if="historial.length > 0" transition="slide-x-transition"
          align-top
          dense
      >
        <v-timeline-item v-for="item in historial" :key="item.id"
            :color="item.state | fixStateColor"
            small
        >
          <v-row class="pt-1">
            <v-col cols="3">
              <div class="d-block">
                <strong class="d-block">{{ item.created_at | fixDate}}</strong>
                <label class="d-block">{{ item.created_at | fixDateHour }}</label>
              </div>
            </v-col>
            <v-col>
              <strong>{{item.state}}</strong>
              <div v-if="item.state == 'Rechazado'" class="text-caption mb-2">
                {{item.comment}}
              </div>
              <v-row>
                <v-col cols="2">
                  <v-avatar>
                    <v-img :src="item.user.pictures[0].url" v-if="item.user && item.user.pictures && item.user.pictures.length>0"></v-img>
                    <v-icon x-large v-else>mdi-account-circle</v-icon>
                  </v-avatar>
                </v-col>
                <v-col class="pl-0">
                  <label class="d-block">
                    <strong>{{item.user.name}} {{item.user.last_name}}</strong>
                  </label>
                  <label class="d-block role-user">{{item.user.roles}}</label>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
      <div class="div-empty" v-else>
        <EmptyContent text="No se ha realizado aún ningún cambio de estado"/>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import EmptyContent from "@/components/empty-content/EmptyContent";
export default {
  name: "ItemHistorialChange",
  props: ["historial"],
  components: {EmptyContent},
  filters: {
    fixStateColor(value) {
      switch (value) {
        case 'Rechazado':
          return "#CA2B2BFF";
        case 'Pendiente':
          return "#6E87DBFF";
        case 'Aprobado':
          return "#6bc263";
        default:
          return "#000";
      }
    },
    fixDate(dateStr){
      const date = new Date(dateStr);
      let month = date.getMonth() + 1;
      month = month>9?month: `0${month}`;
      return `${date.getFullYear()}-${month}-${date.getDate()}`;
    },
    fixDateHour(dateStr){
      const date = new Date(dateStr);
      let hours = date.getHours();
      hours = hours < 10 ? `0${hours}` : hours;
      let minutes = date.getMinutes();
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      let seconds = date.getSeconds();
      seconds = seconds < 10 ? `0${seconds}` : seconds;
      return `${hours}:${minutes}:${seconds}`;
    }
  }
}
</script>

<style scoped>
.role-user{
  font-size: 12px;
}
.div-empty {
  width: fit-content;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2.8rem;
}
</style>